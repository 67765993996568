// Accordion.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FaUserEdit } from "react-icons/fa";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import Footer1 from "../footer1";
import Breadcrumbs from "../breadcrumbs1";
import { useNavigate } from "react-router-dom";
import spinner from "../../images/mailloader/Spinner-5.gif";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isproceed, setIsproceed] = useState([]);
  const MySwal = withReactContent(Swal);
  const storedUserId = localStorage.getItem("userId");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const userEmail =
    location.state?.userEmail || localStorage.getItem("userEmail");

  const getOrderid = `giftgeenie_${Date.now()}`;

  const initialFormData = {
    first_name: "",
    last_name: "",
    user_email: "",
    user_pass: "",
    user_phone: "",
    user_address: "",
    billing_name: "",
    billing_address: "",
    user_pincode: "",
    city: "",
    state: "",
    country: "",
    alternate_phone: "",
    OrderId: getOrderid,
  };

  const [formData, setFormData] = useState(initialFormData);

  // Flag to track form submission
  let formOk = false;

  // Function to handle popstate event and enforce redirection
  const handlePopstate = () => {
    if (formOk) {
      window.history.pushState(null, "", "/");
    }
  };

  // Attach popstate event listener
  window.addEventListener("popstate", handlePopstate);

  // Function to disable back navigation after form submission
  const disableBackNavigation = () => {
    window.history.pushState(null, "", "/");
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  useEffect(() => {
    checkProfile();
  }, [userEmail]);

  const checkProfile = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProfile.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        const responseData = response.data.res[0];

        setFormData(responseData);
      } else {
        console.error("Failed to check authentication");
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const SendMail = async () => {
    setIsLoading(true);

    // Check if any field in formData (except alternate_phone) is empty
    const requiredFields = Object.keys(formData).filter(
      (key) => key !== "alternate_phone"
    );
    if (requiredFields.some((key) => formData[key] === "")) {
      setIsLoading(false);

      // Show SweetAlert for empty fields
      MySwal.fire({
        icon: "error",
        title: "Required Fields",
        text: "Please fill in all the required fields.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the mail sending if any required field is empty
    }

    // Check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.user_email)) {
      setIsLoading(false);

      // Show SweetAlert for invalid email
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });

      return; // Do not proceed with the mail sending if the email is invalid
    }

    Swal.fire({
      icon: "info",
      title: "Processing...",
      html: "Please wait while we process your request.",
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getcallEmail.php?user_email=${userEmail}&user_Id=${storedUserId}`
      );

      if (response.status === 200) {
        Swal.close();

        resetFormData();
        setFormSubmit(true);

        MySwal.fire({
          text: "Your Order Has Been Successfull Placed Our Team Will Contact You Shortly",
          icon: "success",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
          showDenyButton: false,
          showConfirmButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Set the flag to indicate form submission
            formOk = true;
            // Call the function to disable back navigation
            disableBackNavigation();
            // Reset form and set form submission flag
            navigate("/");
          }
        });
      } else {
        Swal.close();
        console.error("Failed to Send Mail");
      }
    } catch (error) {
      Swal.close();
      console.error("Failed to Send Mail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Password validation
    if (name === "user_pass") {
      setPasswordVisible(false); // Hide password when editing
      const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
      if (!passwordRegex.test(value) && value !== "") {
        // Password should be at least 8 characters long and contain at least one letter and one number
        setFormSubmitted(false);
      }
    }

    // Phone validation
    if (name === "user_phone") {
      const phoneRegex = /^\d+$/;
      if (!phoneRegex.test(value) && value !== "") {
        // Phone should only contain numbers
        setPhoneError(true);
      } else {
        setPhoneError(false);
      }
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setFormSubmit(false);

    // Check for email and phone number validation
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.user_email);
    const isInternationalPhoneValid = /^\+?[0-9]{1,15}$/.test(
      formData.user_phone
    );

    if (!isEmailValid) {
      MySwal.fire({
        icon: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    if (!isInternationalPhoneValid) {
      MySwal.fire({
        icon: "error",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number.",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
      return;
    }

    axios
      .post(
        "https://clientgiftgeenie.design247.in/giftgeenieapi/updateProfileDeatils.php",
        formData
      )
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire({
            text: "Data Updated Successfully",
            icon: "success",
            confirmButtonColor: "#96887d",
            confirmButtonBorder: "#96887d",
          });

          checkProfile();
        }
        setEditMode1(false);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  const handleEditModeToggle = () => {
    setEditMode1(!editMode1); // Toggle the edit mode
  };

  useEffect(() => {
    getCheckoutData();
  }, []);

  const getCheckoutData = async () => {
    setIsLoading(true);
    try {
      const CheckoutResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getcheckoutDetails.php?user_Id=${storedUserId}`
      );
      if (CheckoutResponse.status === 200) {
        const CheckoutResponseData = CheckoutResponse.data.res;
        setIsproceed(CheckoutResponseData);
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setIsproceed([]);
      } else {
        console.log(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const mappedData = isproceed.map((item) => ({
    src: `https://overseasgift.design247.in/${item.prod_img}`,
    text: item.prod_name,
    price: parseFloat(item.prod_cost),
    prd_id: item.prod_id,
    prd_qty: item.prod_qty,
    currency: item.prod_currency,
  }));

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "1":
        return "$";
      case "2":
        return "£";
      case "3":
        return "€";
      default:
        return "€"; // Default to '$' if the region is not matched
    }
  };

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const calculateTotal = () => {
    // Add your logic to calculate the total amount based on the form data
    // For example, summing up the product quantities multiplied by their prices
    return mappedData.reduce(
      (total, item) => total + item.prd_qty * item.price,
      0
    );
  };

  return (
    <div>
      <Navbar />

      <Breadcrumbs
        level1="Product Catalogue"       
      />

      <div className="container mx-auto mt-4 flex flex-col sm:flex-row px-4">
        <div className="w-full sm:w-3/4 pr-4 sm:pr-8">
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 border-b border-gray-300">
              Catalouge Checkout Information
            </h2>

            {formSubmit ? (
              <p>The form has been submitted.</p>
            ) : (
              <form className="grid grid-cols-1 gap-4" autocomplete="off">
                <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={handleEditModeToggle}
                      className="text-sm font-semibold rounded-md text-white bg-[#96887d] px-3 py-2 border border-1 border-black-600 ml-2 mr-2"
                    >
                      {editMode1 ? "Cancel" : <FaUserEdit />}
                    </button>
                    {editMode1 && (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="rounded-md bg-[#C8AE7D] px-3 py-2 text-sm font-semibold text-white shadow-sm"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="relative mb-4 md:mb-0">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="firstname"
                      value={formData.first_name}
                      disabled
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          first_name: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div class="relative">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="lastname"
                      value={formData.last_name}
                      disabled
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          last_name: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="relative mb-4 md:mb-0">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="email"
                      value={formData.user_email}
                      disabled
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          user_email: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                  </div>

                  {/* <div className="relative mb-4 md:mb-0">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    id="floating_outlined"
                    name="user_pass"
                    value={formData.user_pass}
                    disabled={!editMode1}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        user_pass: e.target.value,
                      }))
                    }
                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  />
                  {editMode1 && (
                    <span
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className="absolute top-1/4 right-10 transform -translate-y-[-4px] cursor-pointer"
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  )}
                  <label
                    for="floating_outlined"
                    class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Password
                  </label>
                </div> */}

                  <div class="relative">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="user_phone"
                      disabled={!editMode1}
                      maxLength={10}
                      value={formData.user_phone}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          user_phone: e.target.value,
                        }))
                      }
                      class={`block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
              ${phoneError ? "border-red-500" : ""}`}
                    />
                    {phoneError && (
                      <p className="text-red-500 text-sm mt-1">
                        Only numbers are allowed for phone.
                      </p>
                    )}
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Phone <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="relative mb-4 md:mb-0">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="billing_name"
                      value={formData.billing_name}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          billing_name: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Billing Name <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div class="relative col-span-2">
                    <textarea
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="billing_address"
                      value={formData.billing_address}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          billing_address: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Billing Address <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="relative mb-4 md:mb-0">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="user_pincode"
                      value={formData.user_pincode}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          user_pincode: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Pincode <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="relative mb-4 md:mb-0">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="city"
                      value={formData.city}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          city: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      City <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div class="relative">
                    <input
                      type="text"
                      id="floating_outlined"
                      autocomplete="off"
                      name="state"
                      value={formData.state}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          state: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      State <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div class="relative">
                    <input
                      type="text"
                      id="floating_outlined"
                      autoComplete="off"
                      name="country"
                      value={formData.country}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          country: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Country <span className="text-red-500">*</span>
                    </label>
                  </div>

                  {/* <div className="relative">
                  <label class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    Country
                  </label>
                  <select
                    name="region"
                    value={formData.region}
                    disabled={!editMode1}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        region: e.target.value,
                      }))
                    }
                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                  >
                    <option value={0} disabled>
                      Select a region
                    </option>
                    <option value={1}>USA</option>
                    <option value={2}>UK</option>
                    <option value={3}>EUROPE</option>
                  </select>
                </div> */}

                  <div class="relative">
                    <input
                      type="text"
                      id="floating_outlined"
                      autoComplete="off"
                      name="alternate_phone"
                      disabled={!editMode1}
                      maxLength={10}
                      value={formData.alternate_phone}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          alternate_phone: e.target.value,
                        }))
                      }
                      class={`block  pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer
              ${phoneError ? "border-red-500" : ""}`}
                    />
                    {phoneError && (
                      <p className="text-red-500 text-sm mt-1">
                        Only numbers are allowed for phone.
                      </p>
                    )}
                    <label
                      for="floating_outlined"
                      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Alternate Phone (Optional)
                    </label>
                  </div>
                  <div className="relative">
                    <input
                      type="hidden"
                      id="floating_outlined"
                      autocomplete="off"
                      name="password"
                      value={formData.user_pass}
                      disabled={!editMode1}
                      onChange={(e) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          user_pass: e.target.value,
                        }))
                      }
                      class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>

        <div className="w-full sm:w-1/4 mt-4 sm:mt-8">
          <div className="bg-white shadow-md p-4 rounded-lg">
            <h2 className="text-xl font-bold mb-4 border-b border-gray-300">
              Selected Items
            </h2>
            <ul className="list-decimal pl-4 mb-4">
              {mappedData.map((item) => (
                <li key={item.prd_id}>
                  {item.text}: {item.prd_qty} x{" "}
                  {getCurrencySymbol(item.currency)}
                  {parseFloat(item.price).toFixed(2)} ={" "}
                  {getCurrencySymbol(item.currency)}
                  {(item.prd_qty * item.price).toFixed(2)}
                </li>
              ))}
            </ul>
            <div className="border-t border-gray-300 pt-4">
              <p className="text-lg font-semibold">
                Total Amount: {getCurrencySymbol(mappedData[0]?.currency)}
                {calculateTotal().toFixed(2)}
              </p>
            </div>
            <button
              onClick={SendMail}
              className="bg-[#96887d] text-white px-4 py-2 mt-4 w-full"
              disabled={isLoading}
              // disabled={isLoading || !isFormValid()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default Accordion;
