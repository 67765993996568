import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

function Breadcrumbs({ level1, level2, level3, level4 }) {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 mt-2 mb-4">
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 ">
          <li className="inline-flex items-center">
            <button
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-[#96887d]"
              onClick={() => navigate("/")}
            >
              <FaHome className="mr-2" />
              Home
            </button>
          </li>

          <li>
            <div className="flex items-center">
              <IoIosArrowForward />
              {level1 ? (
                <button
                  onClick={handleBack}
                  className="ms-1 text-sm font-medium text-gray-700 hover:text-[#96887d] md:ms-2"
                >
                  {level1}
                </button>
              ) : (
                ""
              )}
            </div>
          </li>

          <li>
            <div className="flex items-center">
              {level1 && <IoIosArrowForward />}
              {level2 && (
                <button
                  onClick={handleBack}
                  className="ms-1 text-sm font-medium text-gray-700 hover:text-[#96887d] md:ms-2"
                >
                  {level2}
                </button>
              )}
            </div>
          </li>

          <li>
            <div className="flex items-center">
              {level2 && <IoIosArrowForward />}
              {level3 && (
                <button
                  onClick={handleBack}
                  className="ms-1 text-sm font-medium text-gray-700 hover:text-[#96887d] md:ms-2"
                >
                  {level3}
                </button>
              )}
            </div>
          </li>

          <li>
            <div className="flex items-center">
              {level3 && <IoIosArrowForward />}
              {level4 && (
                <button
                  onClick={handleBack}
                  className="ms-1 text-sm font-medium text-gray-700 hover:text-[#96887d] md:ms-2"
                >
                  {level4}
                </button>
              )}
            </div>
          </li>
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumbs;
