import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Navbar from "../components/Navbar";
import Footer1 from "../components/footer1";
import Breadcrumbs from "../components/breadcrumbs1";
import jsPDF from "jspdf";
import "jspdf-autotable";

function OrderPage() {
  const [isLoading, setIsLoading] = useState(true);
  const MySwal = withReactContent(Swal);
  const storedUserId = localStorage.getItem("userId");
  const [isOrder, setIsOrder] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 5;

  const exportToPDF = (orderGroup) => {
    const pdf = new jsPDF();

    const startY = 20;

    pdf.setFontSize(14);
    pdf.text(`Order ID: ${orderGroup.orderId}`, 20, startY);
    pdf.text(
      `Order Date: ${new Date(orderGroup.orderDate).toLocaleDateString(
        "en-US"
      )}`,
      20,
      startY + 10
    );

    orderGroup.products.forEach((product, productIndex) => {
      const yOffset = startY + 30 + productIndex * 60;
      pdf.setFontSize(12);
      pdf.text(`Product: ${product.productName}`, 20, yOffset);
      pdf.text(`Qty: ${product.productQty}`, 20, yOffset + 10);
      pdf.text(`Price: $${product.productPrice.toFixed(2)}`, 20, yOffset + 20);

      // const imageX = 150;
      // const imageY = yOffset - 5;
      // const imageWidth = 40;
      // const imageHeight = 40;

      // // Add a border around the image
      // pdf.setLineWidth(1);
      // pdf.rect(imageX - 2, imageY - 2, imageWidth + 4, imageHeight + 4);

      // // Determine image format and add the image accordingly
      // const imageFormat = product.productImage.endsWith(".jpg")
      //   ? "JPEG"
      //   : "JPEG";

      // pdf.addImage(
      //   product.productImage,
      //   imageFormat,
      //   imageX,
      //   imageY,
      //   imageWidth,
      //   imageHeight
      // );

      // Add a line after each product
      pdf.setLineWidth(0.5);
      pdf.line(10, yOffset + 35, 200, yOffset + 35);
    });

    pdf.save(`orderDetails_${orderGroup.orderId}.pdf`);
  };

  useEffect(() => {
    OrderData();
  }, []);

  const OrderData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getOrderDetails.php?user_Id=${storedUserId}`
      );

      if (response.status === 200) {
        const CheckoutResponseData = response.data.res;
        // setIsOrder(CheckoutResponseData);
        // Check if the response data is empty
        if (CheckoutResponseData.length === 0) {
          // Display a message indicating that there are no orders
          console.warn("No orders found for this user.");
        } else {
          // Set the state with the fetched data
          setIsOrder(CheckoutResponseData);
        }
      } else {
        console.error("No Data Fetched");
      }
    } catch (error) {
      console.error("Failed to Fetch The Data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const groupedOrders = {};
  isOrder.forEach((item) => {
    if (!groupedOrders[item.order_id]) {
      groupedOrders[item.order_id] = {
        orderId: item.order_id,
        orderDate: item.checkout_date,
        firstName: item.first_name,
        lastName: item.last_name,
        finalName: item.first_name + " " + item.last_name,
        products: [],
      };
    }
    groupedOrders[item.order_id].products.push({
      productImage: `https://overseasgift.design247.in/${item.prod_img}`,
      productName: item.prod_name,
      productQty: item.prod_qty,
      productPrice: parseFloat(item.prod_cost),
      status: "completed",
    });
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = Object.values(groupedOrders).slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const renderProducts = (orderGroup, orderGroupIndex) => (
    <div key={`orderGroup-${orderGroupIndex}`} className="p-4 mx-auto">
      <div className="rounded-md p-4 shadow-2xl mb-4">
        <div className="flex flex-col md:flex-row justify-between bg-[#e0e0e0] items-center p-4 mb-4">
          <div className="mb-4 md:mr-4 md:mb-0">
            <p className="text-sm font-semibold mb-1">
              Order ID: {orderGroup.orderId}
            </p>
            {/* <p className="text-gray-600"></p> */}

            <div>
              <p className="text-sm font-semibold mb-1">
                Order made on: {orderGroup.orderDate} by {orderGroup.finalName}
              </p>
              {/* <p className="text-gray-600">
                      
                    </p> */}
            </div>
          </div>
          <div className="relative">
            <button
              className="bg-[#65451F] text-white px-4 py-2 rounded"
              onClick={() => exportToPDF(orderGroup)}
            >
              Export
            </button>
            <div className="absolute w-full h-1 bg-[#65451F] bottom-0"></div>
          </div>
        </div>
        {orderGroup.products.map((product, productIndex) => (
          <React.Fragment key={`orderItem-${productIndex}`}>
            {productIndex > 0 && <hr className="my-4" />}
            {/* Line separator */}
            {/* Line separator */}
            <div className="flex flex-col md:flex-row items-center mb-4">
              <img
                src={product.productImage}
                alt={product.productName}
                className="w-16 h-16 object-cover mb-4 md:mr-4 md:mb-0"
              />
              <div className="flex-grow">
                <p className="text-lg font-semibold">{product.productName}</p>
                <p className="text-gray-600">Qty: {product.productQty}</p>
                <p className="text-gray-600">$ {product.productPrice}</p>
              </div>
            </div>
            <div>
              <p className="text-sm font-semibold mb-1">
                Status: {product.status}
              </p>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  const renderPagination = () => (
    <div className="flex justify-center mt-4">
      <button
        className="mx-2 px-3 py-1 bg-gray-300 rounded"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>
      {Array.from(
        {
          length: Math.ceil(
            Object.values(groupedOrders).length / productsPerPage
          ),
        },
        (_, index) => (
          <button
            key={`pagination-${index + 1}`}
            className={`mx-2 px-3 py-1 bg-gray-300 rounded ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "text-gray-700"
            }`}
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </button>
        )
      )}
      <button
        className="mx-2 px-3 py-1 bg-gray-300 rounded"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={
          currentPage ===
          Math.ceil(Object.values(groupedOrders).length / productsPerPage)
        }
      >
        {">"}
      </button>
    </div>
  );

  if (isLoading) {
    // If data is still loading, you can show a loading spinner or message
    return (
      <div>
        <Navbar />
        <Breadcrumbs level1="User Orders" level1Path={`/order`} />
        <div className="container mx-auto mt-8">
          <p className="text-[30px] font-semibold ml-6">Loading...</p>
        </div>
        <Footer1 />
      </div>
    );
  }

  // Check if isOrder is not an empty array
  if (!isOrder || isOrder.length === 0 || isOrder === undefined) {
    // If isOrder is undefined or empty, show a message
    return (
      <div>
        <Navbar />
        <Breadcrumbs level1="User Orders" level1Path={`/order`} />
        <div className="container mx-auto mt-8">
          <p className="text-[30px] font-semibold ml-6">
            Please Login to view the Orders.
          </p>
        </div>
        <Footer1 />
      </div>
    );
  }

  // Render the rest of the component based on the fetched data
  return (
    <div>
      <Navbar />
      <Breadcrumbs level1="User Orders" level1Path={`/order`} />
      <div className="container mx-auto mt-8">
        <p className="text-[30px] font-semibold ml-6">Your Orders</p>
        {currentProducts.map(renderProducts)}
        {renderPagination()}
      </div>
      <Footer1 />
    </div>
  );
}

export default OrderPage;
