import React from "react";
import Navbar from "./components/Navbar";
import { CarouselCustomNavigation } from "./components/carousel";
import SectionTitle from "./components/populartitle";
import SectionTitle1 from "./components/populartitle1";
import SectionTitle2 from "./components/populartitle2";
import AboutUs from "./components/aboutus";
import ClientSection from "./components/Client";
import { CarouselCustomNavigation1 } from "./components/newsletter1";
import SocialSidebar from "./components/sidebar";
import ScrollToTopButton from "./components/topbutton";
import CategorySection3 from "./components/categories3";
import CardSection3 from "./components/card3";
import FeaturedSection2 from "../src/components/featured2";
import Footer1 from "./components/footer1";
import FloatingIcon from "./components/whatsapp";

const Home = () => {
  return (
    <div className="container mx-auto w-full">
      <Navbar />
      <div className="container mx-auto overflow-x-hidden">
        <CardSection3 />
        <CarouselCustomNavigation />
        <SectionTitle />
        <FeaturedSection2 />
        <AboutUs />
        <SectionTitle1 />
        <CategorySection3 />
        <CarouselCustomNavigation1 />
        <SectionTitle2 />
        <ClientSection />
        <FloatingIcon />
        <ScrollToTopButton />
        <SocialSidebar />
        <Footer1 />
      </div>
    </div>
  );
};

export default Home;
