import React, { useState, useEffect } from "react";
import { FaStar } from "react-icons/fa";
import { useRegion } from "../Region";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function ProductHeading({ productID, onProductAddOrRemove }) {
  const rating = 4.5;

  const storedUserId = localStorage.getItem("userId");

  // State for the quantity input
  const [isLoading, setIsLoading] = useState(true);
  const MySwal = withReactContent(Swal);
  // const [quantity, setQuantity] = useState(1);
  const [data, setData] = useState([]);
  const { selectedRegion, setRegion } = useRegion();
  const productId = productID;
  const [cartItems, setCartItems] = useState([]);
  const [inCart, setInCart] = useState(false);
  const [ip, setIP] = useState("");
  const storedRegion = sessionStorage.getItem("selectedRegion");

  useEffect(() => {
    getData();
  }, [ip]);

  const getData = async () => {
    try {
      const res = await axios.get("https://api.ipify.org/?format=json");
      setIP(res.data.ip);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDetails();
    checkProduct();
  }, [
    selectedRegion,
    storedRegion,
    productId,
    cartItems,
    inCart,
    storedUserId,
  ]);

  const getDetails = async () => {
    setIsLoading(true);
    try {
      const DetailNameResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProductDetails.php?productID=${productId}&regionId=${storedRegion}`
      );
      if (DetailNameResponse.status === 200) {
        const DetailNameResponseData = DetailNameResponse.data.res;
        setData(DetailNameResponseData);
      } else if (DetailNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCurrencySymbol = (region_id) => {
    switch (region_id) {
      case "1":
        return "$";
      case "2":
        return "£";
      case "3":
        return "€";
      default:
        return "€"; // Default to '$' if the region is not matched
    }
  };

  const productData = data.map((item) => ({
    text: item.prod_name,
    desc: item.prod_desc,
    price: item.prod_cost,
    id: item.prd_id,
    img: item.img_url,
    cost: item.prod_cost,
    shipment: item.shipment_cost,
  }));

  const checkProduct = async () => {
    try {
      const checkProductResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getCheckProducts.php?product_Id=${productId}&user_Id=${storedUserId}`
      );

      if (checkProductResponse.status === 200) {
        setInCart(true);
      } else if (checkProductResponse.status === 404) {
        setInCart(false);
      } else {
        setInCart(false);
      }
    } catch (error) {
      console.error("Error checking product in cart:", error);
      setInCart(false);
    }
  };

  const addToCart = async () => {
    let cartData = [];
    const userEmail = localStorage.getItem("userEmail");

    productData.forEach((item) => {
      const newCartItem = {
        userId: storedUserId,
        productId: item.id,
        productName: item.text,
        productImg: item.img,
        productCurrency: selectedRegion,
        productCost: item.cost,
        prodQty: 1,
        prodShipment: item.shipment,
        Ip: ip,
      };

      cartData.push(newCartItem);
    });

    try {
      const response = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getNavLogin.php?user_email=${userEmail}`
      );

      if (response.status === 200) {
        try {
          const cartResponse = await axios.post(
            `https://clientgiftgeenie.design247.in/giftgeenieapi/insertProductDeatils.php`,
            cartData
          );

          if (cartResponse.status === 200) {
            localStorage.setItem("ProductCount", true);
            // Show a success message using sweetalert2
            Swal.fire({
              icon: "success",
              title: "Thank You!",
              text: "The product has been added to your cart.",
              confirmButtonColor: "#96887d",
              confirmButtonBorder: "#96887d",
            });
            setInCart(true);
            onProductAddOrRemove();
          } else {
            // Handle other status codes or errors
            console.error("Failed to add the product to the cart");
          }
        } catch (error) {
          console.error("Error adding product to cart:", error);
        }
      } else {
        Swal.fire({
          icon: "warning",
          text: "Kindly Login In To Add Products to your Cart",
          confirmButtonColor: "#96887d",
          confirmButtonBorder: "#96887d",
        });
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      Swal.fire({
        icon: "warning",
        text: "Kindly Login In To Add Products to your Cart",
        confirmButtonColor: "#96887d",
        confirmButtonBorder: "#96887d",
      });
    }
  };

  const removeFromCart = async (itemId) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);

    Swal.fire({
      title: "Are you sure you need to remove this product?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const cartDeleteResponse = await axios.get(
            `https://clientgiftgeenie.design247.in/giftgeenieapi/deleteCart.php?productId=${itemId}&userId=${storedUserId}`
          );

          if (cartDeleteResponse.status === 200) {
            // Show a success message using sweetalert2
            Swal.fire({
              icon: "success",
              title: "Item Removed",
              text: "The product has been removed from your cart.",
              confirmButtonColor: "#96887d",
              confirmButtonBorder: "#96887d",
            });
            setInCart(false);
            onProductAddOrRemove();
          } else {
            // Handle other status codes or errors
            console.error("Failed to add the product to the cart");
          }
        } catch (error) {
          console.error("Error adding product to cart:", error);
        }
      }
    });
  };

  // Generate an array of stars based on the rating
  const stars = Array.from({ length: 5 }, (_, index) => (
    <FaStar
      key={index}
      color={index + 1 <= rating ? "#FFD700" : "#C4C4C4"}
      className="text-[12px]"
    />
  ));

  return (
    <div className="mb-4">
      {productData.map((detail, index) => (
        <div key={index} className="mb-4">
          <h2 className="text-md md:text-3xl text-black font-semibold">
            {detail.text}
          </h2>
          {/* <p className="text-gray-600">{detail.desc}</p> */}
          {/* <div className="flex items-center mb-2">
            {stars}
            <span className="ml-2 text-gray-600">{rating}</span>
          </div> */}
          <hr />
          <div className="flex items-center mb-2">
            <p className="text-black mt-2 mb-2 mr-2">Price :</p>
            <p className="text-black mt-2 mb-2">
              {getCurrencySymbol(storedRegion)} {detail.price}
            </p>
          </div>

          <div className="flex items-center mb-2">
            {inCart ? (
              <button
                className="py-[2px] px-[10px] text-[12px] md:py-[5px] md:px-[20px] md:text-[16px] rounded-lg bg-[#65451F] hover:bg-[#937c62] text-white hover:text-white transition-colors 0.3s"
                onClick={() => removeFromCart(detail.id)}
              >
                Remove from Catalouge
              </button>
            ) : (
              <>
                {/* <label className="mr-2">Quantity:</label>
                <input
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  min={1}
                  className="border p-1 mr-2 rounded-lg"
                /> */}
                {/* <Link to="/ProductCheckoutpage"> */}
                <button
                  className="py-[2px] px-[10px] text-[12px] md:py-[5px] md:px-[20px] md:text-[16px] rounded-lg bg-[#65451F] hover:bg-[#937c62] text-white hover:text-white transition-colors 0.3s"
                  onClick={addToCart}
                >
                  Add to Catalouge
                </button>
              </>
            )}
            {/* </Link> */}
          </div>
          <hr />
        </div>
      ))}
    </div>
  );
}

export default ProductHeading;
