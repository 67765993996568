// ThumbnailGallery.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useRegion } from "../Region";

function ThumbnailGallery(productID) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const productId = productID["productID"];
  const { selectedRegion, setRegion } = useRegion();

  useEffect(() => {
    getImages();
  }, [selectedRegion, productId]);

  const getImages = async () => {
    setIsLoading(true);
    try {
      const ImageNameResponse = await axios.get(
        `https://clientgiftgeenie.design247.in/giftgeenieapi/getProductImage.php?productID=${productId}&regionId=${selectedRegion}`
      );
      if (ImageNameResponse.status === 200) {
        const ImageNameResponseData = ImageNameResponse.data.res;

        if (ImageNameResponseData.length > 0) {
          // Set the selected image to the first image in the array
          const firstImageUrl = `https://overseasgift.design247.in/${ImageNameResponseData[0]?.img_url}`;
          setSelectedImage(firstImageUrl);

          // Set the images state
          setImages(ImageNameResponseData);
        } else {
          console.log("No images found");
        }
      } else if (ImageNameResponse.status === 400) {
        // Handle the 400 status if needed
      } else {
        console.log("No Data Fetched");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const thumbnailImagesData = images.map((item) => ({
    image: `https://overseasgift.design247.in/${item.img_url}`,
    id: item.product_id,
  }));

  return (
    <div className="flex flex-row md:flex-row">
      <div className="lg:w-1/4">
        <div className="flex flex-col items-center overflow-y-auto overflow-x-hidden max-h-[400px] h-full w-full md:w-2/4">
          {thumbnailImagesData.map((imageData, index) => (
            <img
              key={index}
              src={imageData.image}
              alt={`Thumbnail ${index}`}
              className={`mb-2 cursor-pointer w-16 h-16 transition-transform transform-gpu hover:scale-105 focus:scale-105 ${
                selectedImage === imageData.image
                  ? "border-2 border-blue-500"
                  : ""
              }`}
              onClick={() => setSelectedImage(imageData.image)}
            />
          ))}
        </div>
      </div>
      <div className="w-full lg:w-3/4">
        <img
          src={selectedImage}
          alt="Selected Image"
          className="mb-2 cursor-pointer w-full h-full object-cover"
        />
      </div>
    </div>
  );
}

export default ThumbnailGallery;
