import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./style.css";
import ImageCarousel1 from "../images/Header Carousel/ImageCarousel1.png";
import ImageCarousel2 from "../images/Header Carousel/ImageCarousel2.png";
import ImageCarousel3 from "../images/Header Carousel/ImageCarousel3.png";
import ImageCarousel4 from "../images/Header Carousel/ImageCarousel4.png";
import { Link } from "react-router-dom";

export function CarouselCustomNavigation() {
  const [activeIndex, setActiveIndex] = useState(0);
  const totalImages = 4;

  const autoAdvanceCarousel = () => {
    const nextIndex = (activeIndex + 1) % totalImages;
    setActiveIndex(nextIndex);
  };

  useEffect(() => {
    const interval = setInterval(autoAdvanceCarousel, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  const carouselImages = [
    {
      image: ImageCarousel1,
      text: "Luxury Client Gifting",
      buttonText: "EXPLORE",
      buttonLink: "/viewproducts/12",
    },
    {
      image: ImageCarousel2,
      text: "Festival Gifting",
      buttonText: "EXPLORE",
      buttonLink: "/viewproducts/11",
    },
    {
      image: ImageCarousel3,
      text: "Food and Beverages",
      buttonText: "EXPLORE",
      buttonLink: "/viewproducts/5",
    },
    {
      image: ImageCarousel4,
      text: "Gadgets & Technology",
      buttonText: "EXPLORE",
      buttonLink: "/viewproducts/1",
    },
  ];

  return (
    <div
      className="carousel-container mx-auto max-w-7xl px-2 sm:px-6 lg:px-10 rounded-2xl mb-4"
      // style={{ width: "100vw", height: "80vh" }}
    >
      <div
        className="rounded-2xl overflow-hidden"
        // style={{ width: "100%", height: "100%" }}
      >
        <Carousel
          showThumbs={false}
          showStatus={false}
          dynamicHeight={true}
          selectedItem={activeIndex}
          onChange={(index) => setActiveIndex(index)}
          emulateTouch={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
          interval={5000} // Interval in milliseconds for automatic sliding
          showArrows={false}
        >
          {carouselImages.map((item, index) => (
            <div key={index}>
              <div className="relative">
                <img
                  src={item.image}
                  alt={`image ${index + 1}`}
                  className="rounded-2xl w-full h-auto"
                />
                <div className="absolute top-1/4 left-[10%] text-white text-center">
                  <p className="text-[14px] md:text-2xl lg:text-3xl text-white">
                    {item.text}
                  </p>
                  <Link to={item.buttonLink}>
                    <button className="text-[12px] md:text-[14px] lg:text-[16px] py-1 px-2 md:py-2 md:px-6 lg:py-2 lg:px-6 font-medium rounded-lg bg-[#EAC696] hover:bg-[#65451F] text-black hover:text-white mt-2 md:mt-4 lg:mt-6 transition-colors 0.3s">
                      {item.buttonText}
                    </button>
                  </Link>
                  {/* <a href={'/productfilterpage'}>
                      <button className="text-[12px] md:text-[14px] lg:text-[16px] py-1 px-2 md:py-2 md:px-6 lg:py-2 lg:px-6 font-medium rounded-lg bg-[#EAC696] hover:bg-[#65451F] text-black hover:text-white mt-2 md:mt-4 lg:mt-6 transition-colors 0.3s">
                        {item.buttonText}
                      </button>
                    </a> */}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
