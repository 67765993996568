// ProductDetailsPage.js

import React from "react";
import ProductCheckoutPage from "./productcheckout";
import Breadcrumbs from "../breadcrumbs1";
import Navbar from "../Navbar";
import Footer1 from "../footer1";

const ProductCheckoutpage = () => {
  return (
    <div>
      <Navbar />
      <Breadcrumbs />
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <ProductCheckoutPage />
      </div>
      <Footer1 />
    </div>
  );
};

export default ProductCheckoutpage;
