import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Tech from "../images/Featured 1/Tech(1).jpg";
import Bottle from "../images/Featured 1/Bottles(1).jpg";
import Bags from "../images/Featured 1/Bags(1).jpg";
import Offices from "../images/Featured 1/office(1).jpg";
import Food from "../images/Featured 1/Food(1).jpg";
import Sports from "../images/Featured 1/Sportswear(1).jpg";
import Apparel from "../images/Featured 1/Apparels(1).jpg";
import Medal from "../images/Featured 1/Medals(1).jpg";
import eco from "../images/Featured 1/eco.jpg";
import Toys from "../images/Featured 1/Toys(1).jpg";
import giftbox from "../images/Featured 1/giftbox.jpg";
import Headphone from "../images/Featured 1/head.jpg";

import { BsArrowRightCircle } from "react-icons/bs";


function FeaturedSection2() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);
  const [isHovered8, setIsHovered8] = useState(false);
  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };

  const handleMouseEnter5 = () => {
    setIsHovered5(true);
  };

  const handleMouseLeave5 = () => {
    setIsHovered5(false);
  };

  const handleMouseEnter6 = () => {
    setIsHovered6(true);
  };

  const handleMouseLeave6 = () => {
    setIsHovered6(false);
  };

  const handleMouseEnter7 = () => {
    setIsHovered7(true);
  };

  const handleMouseLeave7 = () => {
    setIsHovered7(false);
  };

  const handleMouseEnter8 = () => {
    setIsHovered8(true);
  };

  const handleMouseLeave8 = () => {
    setIsHovered8(false);
  };

  const handleMouseEnter9 = () => {
    setIsHovered9(true);
  };

  const handleMouseLeave9 = () => {
    setIsHovered9(false);
  };

  const handleMouseEnter10 = () => {
    setIsHovered10(true);
  };

  const handleMouseLeave10 = () => {
    setIsHovered10(false);
  };

  const handleMouseEnter11 = () => {
    setIsHovered11(true);
  };

  const handleMouseLeave11 = () => {
    setIsHovered11(false);
  };

  return (
    <div className="flex mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mb-8">
      <div className="md:w-full md:px-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="grid gap-4">
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/9`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Medal}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Medals, Trophies & Awards
                  {isHovered && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/5`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Food}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Food & Drinks
                  {isHovered1 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>

            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/14`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={eco}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Eco-friendly & Sustainable
                  {isHovered2 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-4">
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/1`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter3}
                onMouseLeave={handleMouseLeave3}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Tech}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Tech & Gadgets
                  {isHovered3 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/6`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter4}
                onMouseLeave={handleMouseLeave4}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Sports}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Sportswear & Accessories
                  {isHovered4 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>

            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/12`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter5}
                onMouseLeave={handleMouseLeave5}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={giftbox}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Gift Boxes
                  {isHovered5 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-4">
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/2`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter6}
                onMouseLeave={handleMouseLeave6}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Bottle}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Drinkware & Mugs
                  {isHovered6 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/7`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter7}
                onMouseLeave={handleMouseLeave7}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Apparel}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Clothing & Apparel
                  {isHovered7 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/13`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter8}
                onMouseLeave={handleMouseLeave8}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Toys}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Toys & Games
                  {isHovered8 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-4">
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/3`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter9}
                onMouseLeave={handleMouseLeave9}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Bags}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Bags & Backpacks
                  {isHovered9 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/8`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter10}
                onMouseLeave={handleMouseLeave10}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Headphone}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Headphones & Speakers
                  {isHovered10 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
            <div
              className="relative group cursor-pointer"
              onClick={() => {
                navigate(`/viewproducts/4`);
              }}
            >
              <div
                className="flex flex-row items-end p-0 bg-white overflow-hidden rounded-lg"
                onMouseEnter={handleMouseEnter11}
                onMouseLeave={handleMouseLeave11}
              >
                <img
                  className="h-auto w-full block transition duration-300 transform group-hover:scale-110 rounded-lg"
                  src={Offices}
                  alt=""
                />

                <p className="absolute bg-black bg-opacity-80 left-0 w-full text-bold text-center text-white text-[16px] py-2 inline-block rounded-lg">
                  Office & Business
                  {isHovered11 && (
                    <BsArrowRightCircle className="text-lg inline-block ml-2 mb-1" />
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedSection2;
