import React, { useEffect, useState } from "react";
import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Aboutus1 from "../images/AboutUs/aboutus1.jpg";
import Aboutus2 from "../images/AboutUs/aboutus2.jpg";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: "2000" });
  }, []);

  return (
    <div className="relative mb-8" data-aos="fade-up">
      <div
        className="bg-cover bg-center h-64 md:h-96"
        style={{
          backgroundImage: `url(${Aboutus1})`,
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-60"></div>
        {/* Text Content */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-white text-center px-4 md:px-8 lg:px-12 py-4 md:py-8 lg:py-12">
            <h2 className="text-2xl md:text-3xl lg:text-5xl font-bold mb-4 md:mb-8">
              About Us
            </h2>

            <p className="text-sm md:text-base lg:text-lg mb-4 md:mb-8">
              Our curated selection of premium gifts reflects our commitment to
              quality, creativity, and customization. Whether you're looking to
              express gratitude valued clients, reward your hardworking team, or
              enhance your brand image, we are your trusted partner in creating
              memorable gifting experiences.
            </p>

            <span className="text-xs md:text-sm lg:text-base">
              Join us in making every corporate gesture a statement of
              excellence.
            </span>

            {/* <button
              onClick={() => {
                navigate("/aboutdetail");
              }}
              className="block mx-auto my-4 py-1 px-3 md:py-3 md:px-6 lg:py-3 lg:px-6 font-sm md:font-base lg:font-lg rounded-lg bg-[#65451F] hover:bg-[#EAC696] text-white hover:text-white transition-colors 0.3s"
            >
              Read More
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
